<template>
 
 <BuscarCpe/>
</template>

<script>
import HomeView from './views/HomeView.vue'
import BuscarCpe from './views/BuscarCpe.vue'
export default {
  components:{
    HomeView,BuscarCpe
  }
}
</script>


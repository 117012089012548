<template>
<div id="logoempresa" style="right: 0;position: absolute;">
  
</div>
            
  <div class="container py-5 ">
      <div class="row " >
          <div class="col-md-12 ">
              <div class="row ">
                  <div class="col-md-6 mx-auto ">
          <!-- form card login -->
                      <div class="card rounded-3 shadow p-3" style="z-index:100" >
                        <div id="back" class="col-md-12 mb-2"  >
                            <h4 class="col-md-12 text-center mb-4">
                              <br>
                              <div class="align-middle text-white text-lg-center">COMPROBANTES ELECTRÓNICOS
                              </div>
                            </h4>
                        </div>
                          <div class="card-body">
                            <form class="form" role="form" id='busqueda' >

                                  <div class="form-row mb-2">
                                    <div class="col-sm-12 col-lg-6">
                                        <label for="emisor" class="">Ruc Del Emisor:</label>
                                    </div>
                                    <div class="col-sm-12 col-lg-6">
                                      <input type="text" class=" form-control rounded-0" required name="ruc" id="emisor" placeholder="00000000000" v-model="Comprobante.ruc"  >
                                    </div>
                                  </div>
                                  <div class="form-row mb-2">
                                    <div class="col-sm-12 col-lg-6">
                                        <label>Tipo De Comprobante:</label>
                                    </div>
                                    <div class="col-sm-12 col-lg-6">
                                      <select name="tipodoc" class="form-control rounded-0"  v-model="Comprobante.tipoc"  >
                                           <option value="01">Factura Electr&#243;nica</option>
                                           <option value="03">Boleta de Venta</option>
                                           <option value="07">Nota de Cr&#233;dito</option>
                                           <option value="08">Nota de D&#233;bito</option>
										                       <option value="09">Guia Electr&#243;nica</option>
                                      </select>
                                    </div>
                                  </div>
                                   
                                  <div class="form-row mb-2">
                                    <div class="col-sm-12 col-lg-6">
                                        <label for="emisor" class="">Serie:</label>
                                    </div>
                                    <div class="col-sm-12 col-lg-6">
                                      <input type="text" class=" form-control rounded-0" required name="serie" placeholder="F000" maxlength="4"  v-model="Comprobante.seriec">
                                    </div>
                                  </div>
                                  <div class="form-row mb-2">
                                    <div class="col-sm-12 col-lg-6">
                                        <label for="emisor" class="">Número:</label>
                                    </div>
                                    <div class="col-sm-12 col-lg-6">
                                      <input type="text" class=" form-control rounded-0" required name="numeroc" maxlength="7"  placeholder="0000000"  v-model="Comprobante.numeroc" >
                                    </div>
                                  </div>
                                  <div class="form-row mb-2">
                                    <div class="col-sm-12 col-lg-6">
                                        <label for="emisor" class="">Fecha De Emisión:</label>
                                    </div>
                                    <div class="col-sm-12 col-lg-6">
                                      <input id="fechac" type="date" class=" form-control rounded-0" required data-inputmask-alias="datetime"  data-date-format="DD/MM/YY"   data-inputmask-placeholder="DD/MM/AA" name="fechac"  placeholder="DD/MM/AA" v-model="Comprobante.fechac"> 
                                    </div>
                                  </div>
                                  <div class="form-row mb-2">
                                    <div class="col-sm-12 col-lg-6">
                                        <label for="emisor" class="">Monto Total:</label>
                                    </div>
                                    <div class="col-sm-12 col-lg-6">
                                      <input type="number" class=" form-control rounded-0" required name="importec" placeholder="###.##" v-model="Comprobante.importec">
                                    </div>
                                  </div>

                                  <div class="form-row text-center mb-2" >
                                      <div class="alert alert-danger" role="alert" v-if="error">
                                        {{errors}}
                                      </div>
                                      <div class="col-12"><center><div id="recaptcha_submit"></div></center></div>
                                      </div>

                                      <div class="form-row mb-2">
                                        <div class="col-sm-12 col-lg-12 text-center">
                                            <b-button  right type="button" id="submit" @click="checkForm"  variant="success" class="p-2 px-4 btn-block"   alt="Right" > Enviar</b-button> 
                                        </div>
                                      </div>
                                     <div v-if="resultado">
                                        <div v-if="existe" >
                                                <div class="form-row mb-2">
                                                      <input type="hidden" name="accion" value="descargar">
                                                      <div class="col-sm-12 col-lg-12"><center><h2>EXITO!</h2></center></div>
                                                      <div class="col-sm-6 col-lg-3">
                                                      <center><a target="_blank" :href="'//'+ruta+'/'+pdf" ><img src="@/assets/pdf.png" title="Descargar PDF"/></a>
                                                      </center>
                                                      <center>Descargar PDF</center>
                                                </div>

                                                <div class="col-sm-6 col-lg-3">
                                                      <center><a target="_blank" :href="'//'+ruta+'/'+cdr" >
                                                        <img  src="@/assets/cdr.png"  title="Descargar CDR" /></a></center>
                                                      <center>Descargar CDR </center>
                                                </div>
                                                <div class="col-sm-6 col-lg-3">
                                                        <center><a target="_blank" :href="'//'+ruta+'/'+xml" >
                                                        <img  src="@/assets/xml.png"  title="Descargar XML" /></a></center>
                                                        <center> Descargar XML </center>
                                                </div>
                                                <div class="col-sm-6 col-lg-3 ">
                                                        <center>
                                                          <a target="_blank" @click="descargazip" >
                                                          <img style="cursor:pointer" src="@/assets/zip.png" >
                                                          </a>
                                                          </center>
                                                        <center>Descargar Todo </center>
                                                </div>
                                        </div>
                                    </div>
                                        <div v-else >
                                            <div class="form-row mb-2">
                                                  <div class="col-sm-12 col-lg-12"><center><h2>NO SE ENCONTRO COMPROBANTE!</h2></center></div>
                                              </div>
                                        </div>
                                </div>
                                  

                              </form>
                          </div>
                          <!--/card-block-->
                      </div>
                      <!-- /form card login -->

                  </div>


              </div>
              <!--/row-->

          </div>
          <!--/col-->
      </div>
      <!--/row-->
  </div>

</template>

<script>
import '../../public/css/style.css';

import axios from  'axios'
import moment from 'moment'

export default {

data() {
  return {
    errors:"",
     Comprobante: {
        ruc:"",
        tipoc:"01",
        seriec:"",
        numeroc:"",
        fechac:"",
        importec:"",
        
        //ruc:"20519968445",
        //tipoc:"01",
        //seriec:"F001",
        //numeroc:"0029468",
        //fechac:"2022-01-01",
        //importec:75,
        subdominio:"",
     },
    
    error:false,
    existe:false,
    url:"http://190.117.244.142:8080/aveo",
    tienelogo:false,
    resultado: false,

    verpdf:false,
    vercdr:false,
    verxml:false,
    vertodo:false,
    pdf:"",
    cdr:"",
    xml:"",
    archivo:"",
    ruta:""
  }
},
created(){
  document.title = 'Buscar CPE';
  this.obtenerlogo();

},
mounted(){
},
methods: {
  obtenerlogo(){
    var me = this
    var hosto = location.hostname;
    hosto = hosto.toString().replace("www.","")
    var direccion = hosto.split('.')
    me.subdominio = direccion[0];
    var imagen=""

    axios({
      method: 'get',
      url: "http://190.117.244.142:8080/aveo/logos.json",
      //url: "http://127.0.0.1/aveo/logos.json",
      data: {},
      })                
    .then(function(data){
      //console.log(data.data[0].ruta)
      var logo = data.data.find(x => x.empresa = me.subdominio);
      console.log(logo.ruta + " - " + me.subdominio)
          var img = new Image();
          img.crossOrigin = "Anonymous";
          img.src = logo.ruta
          img.width = 250
          img.onload = () => {
           var body = document.getElementById('logoempresa');
           body.appendChild(img);    
          };
    })
    console.log(imagen)
    return imagen
  },
  getHumanDate : function (date) {
                return moment(date, 'YYYY-MM-DD').format('DDMMYY');
            },
            
            getyear : function (date) {
                return moment(date, 'YYYY-MM-DD').format('YYYY');
            },
            getmonth : function (date) {
                return moment(date, 'YYYY-MM-DD').format('MM');
            },
  checkForm(){
    this.errors = [];
    this.error=false;
    this.resultado = true;
    if(this.Comprobante.ruc.length!=11)
    {
       this.errors="El ruc debe ser 11 digitos";
       this.error=true;
    }
    if(this.Comprobante.seriec.length!=4)
    {
       this.errors="La serie debe ser solo 4 digitos";
       this.error=true;
    }
    if(this.Comprobante.numeroc.length!=7)
    {
       this.errors="El numero debe ser 7 digitos";
       this.error=true;
    }
    var me= this
    axios.post(me.url+'/apiefact.php',me.Comprobante,{ emulateJSON: true})                
        .then(function(data){
          //console.log(data.data)
            if(data.data.estado==true){
                me.pdf=data.data.archpdf
                me.cdr=data.data.archcdr
                me.xml=data.data.archxml
                me.ruta = data.data.ruta
                me.archivo=data.data.archivo
                me.existe=true;
            }else{
              me.existe=false;
            }
        })
  },
  descargazip(){
        var vm = this
        let formData = new FormData();
        formData.append('PDF_', "1");
        formData.append('ZIP_', "1");
        formData.append('RZIP_', "1");
        formData.append('PDF_link',"http://"+vm.ruta+vm.pdf);
        formData.append('ZIP_link', "http://"+vm.ruta+vm.cdr);
        formData.append('RZIP_link', "http://"+vm.ruta+vm.xml);
        formData.append('accion', "descargar");
        formData.append('archivo', vm.archivo);
        axios.post('//'+'190.117.187.170:8080/aveo/descargar.php', formData).then((response) => {
          //console.log (response);
        });
  },
async consumirApi(linkrul){
            try {
                const data=await fetch(`http://localhost/aveo/efact/${linkrul}`)
                this.existe=true
            } catch (error) {
              this.existe=false
                console.log(error)
            }
            //console.log(this.existe)
        }
},

 doesFileExist(urlToFile) {
    var xhr = new XMLHttpRequest();
    xhr.open('HEAD', urlToFile, false);
    xhr.send();
    return xhr.status !== 404;
},

}
</script>

